@import '../theme-bootstrap';

/* Mixin function for review star Background image */
$color-blue: #005288;
$color-gray: #969696;

@mixin pr-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 15px;
  $pr-width-value: 15px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/stars/v2/rating-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* Mixin function for pr-button display */
@mixin pr-button-display {
  background: $color-off-black;
  color: $white;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
}
/* Mixin function for review star Large Background image */
@mixin pr-large-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 44px;
  $pr-width-value: 43px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/stars/v2/rating-large-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* start of main power review container */
#power_review_container {
  padding: 0 10px;
}
/* Common Section render for MPP, SPP, Review, Endeca search and Display Page */
#pr-write,
#pr-reviewdisplay,
.product-grid-wrapper,
.product-full__details,
.spp_product__review-snippet,
#pr-reviewsnippet,
.search-wrapper__results {
  .p-w-r {
    /* To display a Star background Image */
    @include pr-background-image();
    margin-#{$ldirection}: 0;
    .product-grid-wrapper & {
      float: $ldirection;
      margin: 0 0 15px 0;
    }
    a {
      color: $color-off-black;
      &:hover {
        color: $color-off-black;
        text-decoration: underline;
      }
    }
    span,
    p {
      @if $typography_cr24 == true {
        @include body-r;
      } @else {
        font-family: $primary-font;
        font-size: 12px;
      }
      color: $color-off-black;
    }
    .pr {
      &-textinput,
      &-textarea {
        @include input-placeholder {
          font-size: 14px;
        }
      }
      &-alert-container,
      &-header-product-img,
      &-header-required,
      &-logo-container,
      &-header-product-name,
      &-review-snapshot-header-intro,
      &-snippet-rating-decimal {
        display: none;
      }
      &-table-cell {
        padding: 0;
        @if $typography_cr24 == true {
          @include heading-lg;
        }
      }
      &-helper-text {
        color: $color-off-black;
        @if $typography_cr24 == true {
          @include body-xs;
        }
      }
      &-clear-all-radios {
        color: $color-blue;
        letter-spacing: 0;
        &:hover {
          color: $color-off-black;
          text-decoration: underline;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &-accessible-btn {
        @include pr-button-display();
      }
      &-header-table {
        .pr-header-title {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      &-snippet-stars-reco {
        &-inline {
          .pr-snippet-read-and-write {
            .spp_wrap & {
              display: inline-block;
              a,
              span {
                text-transform: uppercase;
                text-decoration: underline;
                color: $color-off-black;
                font-weight: bold;
                font-family: $primary-font;
              }
            }
            .pr-snippet-write-review-link {
              .spp-cross-sells__products &,
              .product-grid-wrapper &,
              .search-wrapper & {
                display: none;
              }
              @if $search_modernized_design == true {
                .product-results & {
                  display: none;
                }
              }
            }
          }
          &.pr-snippet-minimal {
            .pr-snippet-stars-container {
              display: inline-block;
            }
            .pr-snippet-read-and-write {
              margin-#{$ldirection}: 6px;
              margin-top: 5px;
              .pr-snippet-review-count {
                padding-#{$ldirection}: 0;
                padding-#{$rdirection}: 7px;
                margin-#{$rdirection}: 8px;
              }
              a {
                text-decoration: none;
              }
              a:hover {
                text-decoration: underline;
              }
            }
          }
          @if $search_modernized_design == true {
            .pr-snippet-stars-container {
              .pr-snippet-rating-decimal {
                .product-results & {
                  display: none;
                }
              }
            }
          }
        }
      }
      /* To display a Review description posted by user */
      &-review-display {
        dt,
        dd,
        dl {
          color: $color-off-black;
          font-family: $primary-font;
          font-size: 12px;
        }
        .pr-rd-description-text {
          @if $typography_cr24 == true {
            @include text-disclaimer;
          } @else {
            font-family: $primary-font;
            font-size: 12px;
          }
          color: $color-off-black;
        }
        .pr-rd-footer {
          padding: 0 0 10px 0;
          .pr-rd-helpful-text {
            display: none;
          }
        }
        .pr-rd-main-header {
          background: none;
          padding: 0 0 22px 0;
          h1 {
            display: none;
          }
        }
      }
    }
    /* For Review Thank You page */
    .thank-you-page {
      h2.headline {
        color: $color-off-black;
      }
      .pr-rating {
        &-number {
          border: 1px solid $color-blue;
        }
        &-date {
          border: none;
        }
      }
      .title {
        color: $color-off-black;
        text-transform: capitalize;
      }
      .pr-dark {
        color: $color-off-black;
        font-weight: bold;
      }
    }
    .pr-snippet-stars {
      background: $white;
    }
  }
}
/* Spp Page Review Container */
.spp_customer_reviews {
  max-width: $max-width;
  margin: 0 auto;
  padding: 20px;
  clear: both;
  .spp_reviews_button {
    a.button:hover,
    a.button:focus {
      background: $color-off-black;
      color: $white;
    }
  }
  @include breakpoint($small-down) {
    padding: 25px 0 0 0;
    background: $white;
  }
  .spp_reviews {
    &_heading {
      float: $ldirection;
      h2 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
      }
    }
    &_button {
      float: $rdirection;
      display: none;
      @include breakpoint($small-down) {
        padding-#{$rdirection}: 10px;
      }
      input[type='submit'] {
        letter-spacing: 0;
      }
      a.button {
        color: $color-off-black;
        font-weight: bold;
        font-size: 14px;
        line-height: get-line-height(14px, 18px);
        font-family: $primary-font;
      }
    }
    .spp_reviews_heading {
      @include breakpoint($small-down) {
        padding-#{$ldirection}: 10px;
        display: inline;
        float: none;
      }
      @if $typography_cr24 == true {
        @include heading-sm;
      } @else {
        font-family: $primary-font;
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
      }
      text-align: $ldirection;
      color: $color-off-black;
      float: none;
      .tabs-nav {
        @media (min-width: $portrait-up) {
          display: flex;
          justify-content: center;
          border-bottom: 1px solid $color-off-black;
        }
        li {
          @media (min-width: $portrait-up) {
            padding: 0 15px;
            width: 250px;
            border: 1px solid $color-gray;
            border-bottom: 0;
            display: flex;
            justify-content: center;
            line-height: get-line-height(24px, 50px);
            top: 1px;
            height: 50px;
            color: $color-gray;
            cursor: pointer;
            &.js-pr-rating-tab {
              border-#{$rdirection}: 0;
            }
            &:last-child {
              border-#{$ldirection}: 0;
            }
            &.tab-active {
              border: 1px solid $color-off-black;
              border-bottom: 1px solid $white;
              color: $color-off-black;
              background: $white;
            }
          }
          padding: 5px 15px;
          position: relative;
          line-height: get-line-height(24px, 40px);
          &.tab-active {
            background: $color-light-linen;
            @media (min-width: $portrait-up) {
              background: $white;
            }
            &:after {
              transform: rotateZ(90deg);
              top: 19px;
              #{$rdirection}: 25px;
            }
          }
          &:after {
            @include breakpoint($med-small-down) {
              content: '\203A';
            }
            position: absolute;
            font-size: 42px;
            width: 14px;
            height: 14px;
            #{$rdirection}: 40px;
            top: 3px;
            transition: all 0.4s ease;
          }
        }
      }
    }
  }
  .spp_product__review-snippet {
    .pr-review-snippet-container {
      .pr-snippet-stars-reco-reco {
        display: none;
      }
      .pr-snippet-stars-reco-inline {
        margin: 0 0 10px 0;
      }
    }
  }
  #pr-reviewdisplay {
    .p-w-r {
      margin-top: 35px;
      @include breakpoint($landscape-up) {
        margin-top: 0;
      }
      #pr-image-display {
        display: none;
      }
      .pr-snippet-review-count {
        display: block;
      }
      .pr-review-snapshot-msq-container {
        display: none;
      }
      & :focus {
        outline: none;
        box-shadow: none;
      }
      .pr-snippet-standard {
        text-align: center;
        width: 100%;
        display: inline-block;
        .pr-snippet-stars-reco-stars {
          float: none;
          line-height: 15px;
        }
      }
      .pr-review-snapshot-simple,
      .pr-snippet-standard,
      .pr-snippet-compact,
      .pr-snippet-minimal {
        .pr-review-snapshot-snippets {
          .pr-snippet-read-and-write a {
            display: none;
          }
        }
        .pr-snippet-reco-to-friend-green {
          background: none;
          svg {
            display: none;
          }
          & circle {
            display: none;
          }
          .pr-checkbox-icon {
            display: none !important;
          }
        }
      }
      .pr-accordion-btn {
        letter-spacing: 0;
        font-weight: bold;
      }
      .pr-review {
        margin-top: 40px;
        border-bottom: 1px solid $color-off-black;
        @include breakpoint($small-down) {
          padding: 0 15px;
        }
        .pr-rd-merchant-response {
          .pr-rd-merchant-response-headline {
            @if $typography_cr24 == true {
              @include text-disclaimer;
            }
          }
        }
      }
      .pr-review-snapshot {
        background-color: $white;
        .pr-review-snapshot-content-block {
          margin: 0;
          .pr-review-snapshot-snippets {
            @include breakpoint($medium-up) {
              #{$ldirection}: 35%;
            }
            position: relative;
            .pr-snippet-stars-container {
              width: 100%;
              .pr-snippet-stars {
                display: block;
                .pr-rating-stars {
                  float: none;
                }
                .pr-snippet-rating-decimal {
                  display: block;
                  float: none;
                  margin-top: 10px;
                }
              }
            }
            .pr-snippet-read-and-write {
              margin-top: 80px;
              width: 85%;
              @include breakpoint($medium-up) {
                width: auto;
              }
              .pr-snippet-write-review-link {
                letter-spacing: 2px;
                text-decoration: none;
              }
            }
            .pr-snippet-stars-reco-reco {
              position: absolute;
              top: 70px;
              #{$ldirection}: 78px;
              @include breakpoint($medium-up) {
                top: 75px;
                #{$ldirection}: 0;
              }
              .pr-snippet-reco-to-friend {
                text-align: center;
                display: block;
                max-width: 223px;
                div,
                span {
                  display: initial !important;
                  font-weight: normal;
                  white-space: normal;
                }
              }
            }
          }
        }
        .pr-review-snapshot-block-histogram {
          .pr-ratings-histogram {
            width: 80%;
            margin-#{$ldirection}: auto;
            margin-#{$rdirection}: auto;
            @include breakpoint($landscape-up) {
              width: 100%;
            }
          }
        }
      }
      .pr-review-snapshot-snippets {
        .pr-snippet {
          text-align: center;
          @include breakpoint($small-down) {
            top: 15px;
            margin-bottom: 10px;
            display: inline-block;
          }
          .pr-snippet-rating-decimal {
            @if $typography_cr24 == true {
              @include heading-2xl;
            } @else {
              font-size: 40px;
              font-family: $primary-font;
              @include breakpoint($landscape-up) {
                font-size: 35px;
              }
            }
            width: auto;
            display: inline;
            background: $white;
            border: none;
            vertical-align: sub;
            padding: 3px 0 0 0;
            color: $color-off-black;
            &:after {
              @if $typography_cr24 == true {
                @include heading-2xl;
              } @else {
                font-family: $primary-font;
              }
              content: attr(data-after-content);
              color: $color-gray;
            }
          }
        }
        .pr-snippet-stars-reco-reco {
          float: none;
        }
      }
      .pr-accordion {
        .pr-accordion-btn {
          span {
            @if $typography_cr24 == true {
              @include body-xs-bold;
            } @else {
              font-size: 14px;
              font-weight: bold;
            }
          }
          .pr-caret-icon__line {
            stroke: $color-off-black;
          }
          .pr-caret-icon {
            svg {
              display: inline;
            }
          }
        }
      }
      .pr-reco-value,
      .pr-reco-to-friend-message {
        @if $typography_cr24 == true {
          @include body-r;
        } @else {
          line-height: get-line-height(18px, 20px);
          font-family: $primary-font;
          font-size: 18px;
        }
        vertical-align: top;
      }
      @include breakpoint($small-down) {
        .pr-modal-shown {
          z-index: 107159;
        }
      }
    }
    .pr-review-display {
      background-color: $white;
      .pr-rd-review-headline {
        color: $color-off-black;
        text-transform: capitalize;
        padding: 1px 0 0 0;
        line-height: get-line-height(16px, 24px);
        font-size: 16px;
        font-family: $primary-font;
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          width: 100%;
        }
        .pr-rd-to-top {
          @include breakpoint($landscape-up) {
            padding-#{$ldirection}: 31%;
          }
          @if $typography_cr24 == true {
            @include body-xs;
          } @else {
            font-family: $primary-font;
            font-size: 14px;
          }
          text-align: center;
        }
        .pr-rd-pagination-btn {
          text-transform: capitalize;
          color: $color-off-black;
          font-size: 14px;
          font-family: $primary-font;
        }
      }
      .pr-flagging-radio {
        padding-#{$ldirection}: 20px;
      }
      .pr-rd-sort-group {
        padding: 0 10px;
        margin-top: 10px;
        float: none;
        text-align: center;
        @include breakpoint($landscape-up) {
          padding: 0;
          margin-top: 0;
          float: $rdirection;
        }
        label,
        select {
          font-family: $primary-font;
          font-size: 14px;
          background-color: $white;
        }
        label {
          color: $color-off-black;
          text-transform: uppercase;
          font-weight: normal;
          display: none;
        }
        .pr-rd-sort {
          min-width: 140px;
          border: 1px solid $color-off-black;
          width: 85%;
          margin: 35px auto 0;
          @include breakpoint($landscape-up) {
            width: auto;
            margin: 0 0 12px;
          }
        }
      }
      .pr-rd-def-list dd {
        @if $typography_cr24 == true {
          @include body-r;
        } @else {
          font-weight: normal;
          font-size: 14px;
        }
      }
      .pr-rd-def-list dt {
        @include swap_direction(padding, 0 15px 5px 0);
        @if $typography_cr24 == true {
          @include body-r-bold;
        } @else {
          font-weight: bold;
          font-size: 14px;
          text-transform: capitalize;
        }
        width: 285px;
        height: auto;
      }
      .pr-rd-subratings {
        padding-bottom: 20px;
        @include breakpoint($small-down) {
          padding-bottom: 0;
        }
      }
      .pr-rd-header.pr-rd-content-block,
      .pr-rd-description.pr-rd-content-block,
      .pr-rd-content-block.pr-accordion,
      .pr-rd-footer.pr-rd-content-block,
      .pr-rd-images.pr-rd-content-block {
        width: 80%;
        padding-#{$rdirection}: 24px;
        @include breakpoint($small-down) {
          border-#{$rdirection}: none;
          margin-bottom: 0;
          width: 100%;
          padding-#{$rdirection}: 0;
        }
      }
      .pr-rd-review-tag {
        dt {
          @if $typography_cr24 == true {
            @include body-r-bold;
          } @else {
            font-size: 14px;
          }
          background: none;
          padding-bottom: 0;
          padding-top: 0;
        }
        dd {
          @if $typography_cr24 == true {
            @include body-r;
          } @else {
            font-size: 14px;
          }
          padding-bottom: 0;
        }
      }
      .pr-rd-side-content-block.pr-rd-right {
        .pr-rd-reviewer-type {
          display: none;
        }
        #{$rdirection}: -28%;
        top: -40px;
      }
      .pr-rd-header.pr-rd-content-block,
      .pr-rd-description.pr-rd-content-block {
        margin-bottom: 0;
      }
      .pr-rd-header.pr-rd-content-block {
        .pr-rd-star-rating,
        .pr-rd-review-headline {
          margin-bottom: 15px;
          @include breakpoint($small-down) {
            margin-bottom: 10px;
          }
        }
        .pr-rd-review-headline {
          @if $typography_cr24 == true {
            @include body-xl;
          } @else {
            font-size: 24px;
          }
          clear: both;
          margin-#{$ldirection}: 0;
        }
      }
      .pr-rd-description.pr-rd-content-block {
        .pr-rd-description-text {
          @if $typography_cr24 == true {
            @include body-r;
          } @else {
            font-size: 14px;
          }
          margin-bottom: 15px;
        }
      }
      .pr-rd-helpful-action {
        line-height: 20px;
        margin-bottom: 10px;
        margin-#{$rdirection}: 0;
        .pr-rd-helpful-text {
          font-size: 14px;
          padding-#{$rdirection}: 3px;
          line-height: get-line-height(14px, 20px);
          font-weight: bold;
        }
      }
      .pr-flag-icon {
        svg {
          vertical-align: initial;
          @include breakpoint($small-down) {
            position: relative;
            bottom: 4px;
          }
        }
      }
      .pr-rd-no-reviews {
        .pr-snippet-write-review-link {
          @if $typography_cr24 == true {
            @include link-1-normal;
          }
        }
      }
    }
    .pr-thumbs-icon,
    .pr-rd-bottomline &.pr-rd-bold {
      display: none;
    }
    .pr-rd-reviewer-details {
      p {
        margin-bottom: 5px;
      }
      span,
      time {
        @if $typography_cr24 == false {
          font-weight: normal;
        }
      }
      time {
        font-weight: normal;
        font-size: 14px;
        color: $color-off-black;
      }
    }
    .pr-rd-flag-review-container {
      @include breakpoint($landscape-up) {
        position: static;
        float: #{$ldirection};
        width: auto;
      }
      @if $typography_cr24 == true {
        @include link-1-bold;
      } @else {
        font-family: $primary-font;
        font-size: 14px;
        line-height: 1.5;
        text-transform: uppercase;
        font-weight: bold;
      }
      vertical-align: baseline;
      padding: 10px 0;
      position: relative;
      bottom: 4px;
      display: inline-block;
      float: none;
      width: 100%;
      .pr-flag-review-thankyou {
        font-size: 14px;
        @include breakpoint($small-down) {
          position: relative;
          bottom: 4px;
        }
      }
    }
    .pr-helpful {
      &-count {
        @if $typography_cr24 == true {
          @include body-xs;
        } @else {
          font-size: 15px;
          line-height: get-line-height(15px, 20px);
          font-weight: bold;
        }
        text-transform: uppercase;
        padding-#{$rdirection}: 3px;
        &:hover {
          color: $color-off-black;
        }
      }
      &-btn {
        border: 0;
        padding: 0 1px;
        background: none;
        text-transform: capitalize;
        vertical-align: top;
        font-weight: bold;
        min-width: 90px;
        @include breakpoint($small-down) {
          margin-top: 5px;
          margin-bottom: 10px;
          min-width: 70px;
        }
        &.pr-helpful-active {
          background: none !important;
        }
        .pr-helpful-count {
          border: 1px solid $color-off-black;
          padding: 5px 10px;
          margin-#{$rdirection}: 10px;
          display: block;
          &:after {
            visibility: visible;
            margin-#{$rdirection}: 0;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
      &-yes {
        .pr-helpful-count {
          float: $ldirection;
          &:before {
            visibility: visible;
            margin-#{$rdirection}: 0;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
      &-no {
        .pr-helpful-count {
          &:before {
            visibility: visible;
            margin-#{$rdirection}: 0;
            text-transform: uppercase;
            font-size: 14px;
          }
        }
      }
      .pr-helpful-count {
        font-size: 14px;
        padding-#{$rdirection}: 3px;
        line-height: get-line-height(14px, 20px);
        font-weight: bold;
        display: block;
      }
      .pr-rd-helpful-text {
        line-height: 20px;
        font-weight: bold;
      }
    }
    .pr-rd-details {
      &.pr-rd-author-nickname {
        font-weight: bold;
        font-size: 14px;
        text-transform: capitalize;
      }
      &.pr-rd-author-location {
        font-size: 14px;
      }
      &.pr-rd-content-block {
        margin-top: 20px;
      }
    }
    .pr-rd-images.pr-rd-content-block {
      margin: 0;
    }
    .pr-review-snapshot-faceoff {
      display: none;
    }
  }
}

.spp_wrap {
  .pr-snippet-reco-to-friend {
    display: none;
  }
  .product__rating.desktop-block {
    margin-top: 0;
  }
}

#pr-war-form {
  .pr-cons-form-group,
  .pr-pros-form-group,
  .pr-bestuses-form-group,
  .pr-wasthisagift-form-group {
    display: none;
  }
}

.section-review {
  #power_review_container {
    float: $ldirection;
    width: 100%;
    .product-full {
      &__container {
        background: $white;
        padding: 40px 0 20px;
        position: relative;
      }
      &__name {
        @if $typography_cr24 == true {
          @include heading-2xl;
        }
      }
      &__image {
        width: 25%;
        float: $ldirection;
        @include breakpoint($small-down) {
          width: 100%;
          text-align: center;
        }
        img {
          margin-#{$rdirection}: 10px;
          margin-bottom: 10px;
          float: $ldirection;
          @include breakpoint($small-down) {
            max-width: 75%;
            float: none;
            max-height: 200px;
          }
        }
      }
      &__details {
        float: $ldirection;
        width: 75%;
        @include breakpoint($small-down) {
          width: 100%;
        }
        .product-name {
          position: absolute;
          #{$ldirection}: 0;
          width: 24%;
          color: $color-off-black;
          font-family: $primary-font;
          font-size: 20px;
          line-height: get-line-height(20px, 20px);
          margin-bottom: 15px;
          text-transform: uppercase;
          font-weight: bold;
          @include breakpoint($small-down) {
            top: 67px;
            width: auto;
            text-align: center;
          }
        }
      }
    }
    .p-w-r {
      .thank-you-page {
        .col-sm-12 {
          .pr-rating-number {
            border: none;
            span {
              float: $ldirection;
            }
          }
          span {
            float: none;
            display: inline;
            font-weight: normal;
            padding-#{$ldirection}: 5px;
          }
        }
        .headline {
          font-family: $primary-font;
          font-weight: normal;
        }
      }
      .pr-media-preview {
        .pr-loading-indicator {
          @include breakpoint(($small-down) (orientation portrait)) {
            width: 35px;
            height: 35px;
          }
        }
      }
      .pr-btn-danger {
        min-width: auto;
        color: $white;
        background-color: $color-off-black;
        border-color: $color-off-black;
        @include breakpoint(($small-down) (orientation portrait)) {
          padding: 8px 10px;
        }
      }
      fieldset {
        width: auto;
      }
      .pr-waqwaa {
        .pr-header {
          @include breakpoint($small-down) {
            position: inherit;
          }
        }
      }
      .pr-header {
        border-bottom: none;
        @include breakpoint($small-down) {
          position: absolute;
          top: 10px;
          width: 100%;
        }
      }
      span {
        width: auto;
        @if $typography_cr24 == true {
          @include body-r-bold;
        } @else {
          font-size: 16px;
          line-height: 1.5;
          font-weight: bold;
        }
      }
      .pr-label-control.checked {
        span {
          float: none;
          color: $white;
        }
      }
      .input-add-tag {
        .pr-btn-add-tag-submit {
          margin-#{$ldirection}: 15px;
          color: $color-off-black;
          border-#{$ldirection}: 1px solid $color-darkest-gray;
          @include breakpoint($small-down) {
            margin: 10px 0 0 0;
          }
        }
      }
      label span::first-letter {
        text-transform: capitalize;
      }
      input:not([type='radio']) {
        width: 85% !important;
        font-family: $primary-font;
        color: $color-gray;
        font-size: 16px;
        background: $white;
        @include breakpoint($small-down) {
          width: 100% !important;
        }
      }
      .pr-form-control {
        line-height: 1.1;
      }
      input::placeholder {
        padding-#{$ldirection}: 15px;
        font-size: 16px !important;
        @include breakpoint($small-down) {
          padding: 5px 0 0 0;
        }
        @if $typography_cr24 == true {
          @include body-r;
          text-transform: none;
        }
      }
      textarea::placeholder {
        @if $typography_cr24 == true {
          @include body-r;
          text-transform: none;
        }
      }
      select {
        max-width: 85%;
        height: auto;
        padding-#{$ldirection}: 20px !important;
        color: $color-gray;
        background: $white;
        @if $typography_cr24 == true {
          @include body-r;
        } @else {
          font-family: $primary-font;
          font-size: 16px;
          text-transform: uppercase;
        }
        @include breakpoint($small-down) {
          max-width: 100%;
          padding-#{$ldirection}: 10px !important;
        }
      }
      textarea {
        font-family: $primary-font;
        width: 85% !important;
        font-size: 16px;
        color: $color-gray;
        background: $white;
        @include breakpoint($small-down) {
          width: 100% !important;
        }
      }
      textarea::placeholder {
        padding-#{$ldirection}: 15px;
        font-size: 16px;
        @include breakpoint($small-down) {
          padding: 5px 0 0 0;
        }
      }
      .pr-war {
        max-width: $max-width;
      }
      .pr-header-title {
        color: $color-off-black;
        display: block;
        padding-bottom: 15px;
        margin: 0;
        font-size: 24px;
        @if $typography_cr24 == true {
          font-weight: 400;
          line-height: 0.9;
        } @else {
          font-family: $primary-font;
          line-height: normal;
          font-weight: bold;
        }
      }
      #pr-war-form {
        .form-group {
          input::placeholder {
            font-size: 11px;
            line-height: 1.5;
          }
          .btn-group-2-count {
            width: 100%;
            .pr-btn.pr-btn-default {
              font-size: 16px;
              color: $color-off-black;
              padding-#{$ldirection}: 25px;
              background: $white;
              @if $typography_cr24 == true {
                @include body-r;
              }
            }
            .pr-btn.pr-btn-default.active {
              background: $color-off-black;
              color: $white;
            }
            @include breakpoint($landscape-up) {
              width: 85%;
            }
          }
          .pr-clear-all-radios {
            color: $color-off-black;
            font-size: 16px;
          }
          &.pr-media_image-form-group,
          &.pr-media_videourl-form-group {
            width: 85%;
            @include breakpoint($small-down) {
              width: 100%;
            }
            label {
              color: $white;
              background-color: $color-off-black;
              border-color: $color-off-black;
              padding: 6px;
              width: 195px !important;
              @if $typography_cr24 == true {
                @include body-r;
                text-transform: uppercase;
                @include breakpoint($landscape-up) {
                  text-transform: uppercase;
                }
              }
              @include breakpoint($small-down) {
                width: 180px !important;
              }
              &:hover {
                background-color: $color-off-black;
                border-color: $color-off-black;
              }
              span {
                color: $white;
                &:after {
                  color: $white;
                }
              }
            }
          }
          &.pr-has-error {
            input {
              &.form-control {
                border-color: $color-red;
              }
            }
            label {
              &.pr-control-label span {
                color: $color-red;
              }
            }
          }
          &.pr-rating-form-group {
            label {
              color: $color-off-black;
              span {
                color: $color-off-black;
              }
            }
          }
          .tag-group {
            .pr-label-control {
              &.checked {
                background-color: $color-off-black;
                white-space: initial;
              }
            }
          }
        }
      }
      .pr-clear-all-radios {
        color: $color-off-black;
      }
      .pr-submit {
        .pr-footer {
          .pr-subscript {
            @if $typography_cr24 == true {
              @include body-r;
            } @else {
              font-size: 16px;
              line-height: 1.2;
            }
            a {
              font-size: 16px;
            }
          }
        }
      }
      @if $typography_cr24 == true {
        .pr-btn-add-tag {
          button {
            @include body-xs;
          }
        }
        .pr-control-label {
          @include body-r-bold;
        }
        .pr-btn-fileinput {
          @include body-r;
        }
        .pr-control-label--accept-terms {
          span {
            @include body-l-bold;
          }
        }
        .pr-cb-tag-container {
          span {
            @include body-r;
          }
        }
      }
    }
  }
  .site-footer {
    width: 100%;
  }
}

#pr-write {
  .p-w-r {
    .pr-table-cell:nth-child(2) {
      @include breakpoint($small-down) {
        display: block;
      }
      h3 {
        @include breakpoint($small-down) {
          text-align: center;
        }
      }
    }
    .thank-you-page {
      .title,
      .subtitle,
      h5,
      .pr-rating-date {
        font-family: $primary-font;
      }
      .title {
        margin-top: 0;
      }
      .header {
        margin-top: 0;
      }
      p,
      h5 {
        font-size: 16px;
      }
    }
    .form-group {
      .pr-btn-default {
        span:after {
          color: $color-off-black;
        }
      }
      .btn-group-radio-vertical {
        .pr-btn {
          background: $white;
          font-size: 16px;
          &:hover,
          &:active,
          &:focus {
            background: $color-off-black;
            color: $white;
          }
          &.active {
            background: $color-off-black;
          }
        }
      }
      @include breakpoint($small-down) {
        margin-bottom: 20px;
      }
      &.pr-email_collection-form-group {
        display: none;
      }
    }
    .pr-footer {
      .pr-accessible-btn {
        padding: 9px 15px;
        @if $typography_cr24 == true {
          @include body-xs-uppercase;
          margin-bottom: 20px;
        } @else {
          font-size: 14px;
          font-weight: normal;
        }
      }
      a {
        font-size: 12px;
        text-decoration: underline;
      }
    }
    .pr-alert-container {
      display: block;
      .pr-alert-warning {
        display: none;
      }
    }
  }
}

.js-spp-reviews-mobile {
  #pr-reviewdisplay {
    .p-w-r {
      .pr-review-snapshot {
        .pr-review-snapshot-block-container {
          .pr-review-snapshot-block-histogram {
            margin-top: 0;
          }
        }
      }
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-snapshot {
      .pr-review-snapshot-block-container {
        .pr-review-snapshot-block-headline {
          display: none;
        }
        .pr-review-snapshot-block {
          border: none;
          padding: 10px 0;
          @include breakpoint($landscape-up) {
            padding: 10px 20px;
          }
          min-height: auto;
          display: block;
          dl {
            @if $typography_cr24 == true {
              @include body-xs-bold;
              text-transform: uppercase;
              @include breakpoint($landscape-up) {
                text-transform: uppercase;
              }
            } @else {
              font-family: $primary-font;
            }
          }
        }
        .pr-review-snapshot-block-histogram {
          margin-top: 200px;
          @include breakpoint($landscape-up) {
            width: 40%;
            margin: 0 100px; /* Update this to margin-top: 0; when the pros section is displayed */
          }
          padding-#{$ldirection}: 0;
          .pr-histogram-list {
            .pr-histogram-stars {
              .pr-ratings-histogram-bar {
                border-radius: initial;
                height: 27px;
                .pr-ratings-histogram-barValue {
                  height: 27px;
                  border-radius: unset;
                  background: $color-off-black;
                }
              }
              .pr-histogram-label,
              .pr-histogram-count {
                font-size: 18px;
                font-weight: bold;
              }
            }
          }
        }
        .pr-review-snapshot-block-pros {
          display: none;
          padding: 15px 30px;
          text-align: center;
          .pr-review-snapshot-tags {
            &:before {
              @if $typography_cr24 == true {
                @include body-r-bold;
              } @else {
                font-family: $primary-font;
                font-size: 20px;
                font-weight: bold;
                @include breakpoint($landscape-up) {
                  font-size: 16px;
                }
              }
              content: attr(data-before-content);
              color: $color-off-black;
            }
            .pr-snapshot-tag-def {
              display: none;
              &.pr-snapshot-tag-def-filtering {
                display: block;
                height: auto;
                &:first-of-type {
                  margin: 0;
                }
                &:hover {
                  background: none;
                  .pr-filter-btn {
                    background: none;
                  }
                }
                .pr-filter-btn {
                  @include swap_direction(padding, 2px 2px 2px 10px);
                  text-align: center;
                  &__voteCount {
                    display: none;
                  }
                  &__buttonText {
                    text-transform: none;
                  }
                }
              }
            }
            dt {
              display: none;
            }
          }
        }
        .pr-review-snapshot-block-cons {
          position: absolute;
          @include breakpoint($landscape-up) {
            width: 35%;
            padding-#{$rdirection}: 0;
            position: static;
          }
          .pr-review-snapshot-tags {
            display: none;
          }
          .pr-review-snapshot-snippets {
            position: relative;
            .pr-snippet-stars-container {
              margin: 0 auto;
              width: 100%;
              .pr-rating-stars {
                margin: 0 auto;
                float: none;
                .pr-star-v4 {
                  height: 35px;
                  width: 35px;
                }
              }
              .pr-snippet-rating-decimal {
                width: 100%;
                margin: 10px auto;
              }
            }
          }
          .pr-snippet-reco-to-friend {
            position: absolute;
            top: 110px;
            text-align: center;
            margin-#{$ldirection}: 0;
            width: 100%;
            max-width: 100%;
            @include breakpoint($landscape-up) {
              top: 95px;
            }
            div,
            span {
              display: initial !important;
            }
            .pr-reco-value {
              &:after {
                content: attr(data-after-content);
              }
            }
            .pr-reco-to-friend-message {
              display: none !important;
            }
          }
          .pr-snapshot-tag-def {
            display: none;
          }
        }
      }
    }
    span {
      @if $typography_cr24 == true {
        @include body-r;
      } @else {
        font-size: 15px;
        font-weight: bold;
        @include breakpoint($landscape-up) {
          font-size: 17px;
          font-weight: normal;
        }
      }
      width: auto;
      white-space: nowrap;
      span {
        &:first-child {
          display: none;
        }
      }
    }
    .pr-review-display {
      .pr-rd-main-header {
        border-bottom: 1px solid $color-off-black;
        border-top: 1px solid $color-off-black;
        padding: 15px 0 0;
        @include breakpoint($small-down) {
          margin-top: 2px;
          padding: 0;
        }
        &-with-filters {
          &:before {
            content: attr(data-before-content);
            font-family: $primary-font;
            font-size: 15px;
            font-weight: bold;
            position: absolute;
            @include swap_direction(margin, 5px 0 0 5px);
            @include breakpoint($landscape-up) {
              content: '';
            }
          }
        }
        .pr-rd-review-header-contents {
          background: transparent;
          margin-top: 0;
          @include swap_direction(padding, 15px 0 0 15px);
          text-align: initial;
          @include breakpoint($landscape-up) {
            display: inline-flex;
            width: 63%;
            padding-#{$ldirection}: 0;
            margin-top: 30px;
          }
          .pr-multiselect {
            &.disabled {
              display: none;
            }
            &.active[data-before-content]:not([data-before-content='']) {
              &:before {
                @if $typography_cr24 == true {
                  @include body-r;
                } @else {
                  font-family: $primary-font;
                  @include breakpoint($landscape-up) {
                    content: attr(data-before-content);
                    font-size: 17px;
                    margin-#{$rdirection}: 20px;
                    font-weight: normal;
                  }
                }
              }
              .pr-multiselect-options {
                @include breakpoint($landscape-up) {
                  margin-#{$ldirection}: 95px;
                }
              }
            }
            &-options {
              width: auto;
              @include swap_direction(padding, 10px 5px 10px 10px);
              ul {
                width: auto;
              }
              span {
                font-size: 12px;
                white-space: nowrap;
              }
            }
            &-count {
              background-color: $color-lighter-gray;
            }
            &-item-label {
              @if $typography_cr24 == true {
                @include body-xs;
              }
            }
          }
          .pr-multiselect-button {
            border: none;
            background: transparent;
            display: inline;
            min-width: initial;
            padding-#{$rdirection}: 38px;
            padding-#{$ldirection}: 0;
            width: auto;
            .pr-caret-icon {
              background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAABWCAYAAABb/x+YAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDoxMUU4RDQ4OTFBMjA2ODExODIyQUM1OTU4RUQzMjBCQSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDozREZGMUNGQTlFNkQxMUUzOTVCM0FCNzNDQUVBQjBCMiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDozREZGMUNGOTlFNkQxMUUzOTVCM0FCNzNDQUVBQjBCMiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjBCRTExNjFDMEIyMDY4MTE5MkIwQzExRkE3MzE4M0IzIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjExRThENDg5MUEyMDY4MTE4MjJBQzU5NThFRDMyMEJBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+K+KQIAAAARZJREFUeNrs2jELAWEcx3Enizfg9XhDCoOwKIuNMhgNFqNJRoPFIKOUwaROFkki8vipU7oix3DP6fuv33L3PPXp6bn/8wznGGNiUat4LIIFGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBg/5ntJNMpb+efFgPQ1vpgWICphf29sgp5wBzjko+bPRcaQaYU1eWNnyIVcX9YPxKqdnSPXZK6YPxBWVvU8vrKJM3Y0dK17Y+fVUyXnfw1/1d9sW70A+Xsbfi/morU5tPxKK3xx+1Vcq2H+Our0NUlE0U7h4NZaHMlJZtd4/Ei+enp5PyEhX0vfrW3vL4swY0aNCgQYMGDRo0aNCgQYMGDRo0aNCgQYMGDfrnugkwALhbOXXwEMtoAAAAAElFTkSuQmCC');
              background-size: 23px 43px;
              transform: rotate(0);
              background-repeat: no-repeat;
              height: 25px;
              top: 11px;
              display: inline-block;
              margin-#{$ldirection}: 5px;
              svg {
                display: none;
              }
            }
          }
        }
        .pr-clearfix {
          display: none;
        }
        .pr-rd-main-header-search-sort {
          padding-top: 0;
          @include breakpoint($landscape-up) {
            float: #{$rdirection};
            width: 37%;
          }
          .pr-rd-main-header-search {
            float: #{$rdirection};
            @include breakpoint($landscape-up) {
              width: 100%;
            }
            .pr-rd-search-reviews-input {
              border-bottom: 1px solid $color-off-black;
              width: 85%;
              margin: 0 auto;
              @include breakpoint($landscape-up) {
                border: none;
                width: 93%;
                padding-#{$rdirection}: 108px;
              }
              input {
                @if $typography_cr24 == true {
                  @include body-xs;
                }
                border: none;
                background: transparent;
                @include breakpoint($landscape-up) {
                  border: 1px solid $color-off-black;
                  background-color: $white;
                  margin-#{$ldirection}: 30px;
                }
                &::placeholder,
                &:-ms-input-placeholder {
                  text-transform: Lowercase;
                }
                &:disabled {
                  border: none;
                  outline: none !important;
                }
              }
              .pr-rd-search-reviews-icon-button {
                #{$ldirection}: auto;
                #{$rdirection}: 0;
                min-width: 35px;
                background: $color-off-black;
                border: 1px solid $color-off-black;
                width: auto;
                .pr-search-icon {
                  svg {
                    display: none;
                    path {
                      fill: $color-off-black;
                    }
                  }
                  &:after {
                    @if $typography_cr24 == true {
                      @include body-xs-uppercase;
                    } @else {
                      font-size: 17px;
                      font-family: $primary-font;
                    }
                    color: $white;
                  }
                }
                @include breakpoint($landscape-up) {
                  #{$rdirection}: auto;
                  #{$ldirection}: auto;
                  margin-#{$ldirection}: 10px;
                  min-width: 60px;
                  .pr-search-icon {
                    svg {
                      display: none;
                    }
                  }
                }
                &:disabled {
                  display: none;
                }
              }
              .pr-rd-search-reviews-clear-button {
                #{$rdirection}: 0;
                min-width: 35px;
                @include breakpoint($landscape-up) {
                  #{$rdirection}: 35px;
                }
                svg {
                  width: 30px;
                  color: $color-off-black;
                  .pr-cross-icon__line {
                    stroke: $color-off-black;
                  }
                }
              }
            }
          }
          .pr-rd-review-header-sorts {
            .pr-rd-sort {
              font-size: 20px;
              height: 45px;
              @if $hide_oldest_pw_filter == true {
                option[value='oldest'] {
                  display: none;
                }
              }
            }
            @include breakpoint($landscape-up) {
              float: #{$rdirection};
              width: auto;
              @include swap_direction(padding, 9px 20px 15px 0);
              .pr-rd-sort-group {
                &:before {
                  @if $typography_cr24 == true {
                    @include body-r;
                  } @else {
                    font-size: 17px;
                    font-family: $primary-font;
                  }
                  content: attr(data-before-content);
                }
                .pr-rd-sort {
                  -webkit-appearance: none;
                  cursor: pointer;
                  border: none;
                  font-size: 17px;
                  text-transform: uppercase;
                  width: 140px;
                  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAABWCAYAAABb/x+YAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDoxMUU4RDQ4OTFBMjA2ODExODIyQUM1OTU4RUQzMjBCQSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDozREZGMUNGQTlFNkQxMUUzOTVCM0FCNzNDQUVBQjBCMiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDozREZGMUNGOTlFNkQxMUUzOTVCM0FCNzNDQUVBQjBCMiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjBCRTExNjFDMEIyMDY4MTE5MkIwQzExRkE3MzE4M0IzIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjExRThENDg5MUEyMDY4MTE4MjJBQzU5NThFRDMyMEJBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+K+KQIAAAARZJREFUeNrs2jELAWEcx3Enizfg9XhDCoOwKIuNMhgNFqNJRoPFIKOUwaROFkki8vipU7oix3DP6fuv33L3PPXp6bn/8wznGGNiUat4LIIFGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBg/5ntJNMpb+efFgPQ1vpgWICphf29sgp5wBzjko+bPRcaQaYU1eWNnyIVcX9YPxKqdnSPXZK6YPxBWVvU8vrKJM3Y0dK17Y+fVUyXnfw1/1d9sW70A+Xsbfi/morU5tPxKK3xx+1Vcq2H+Our0NUlE0U7h4NZaHMlJZtd4/Ei+enp5PyEhX0vfrW3vL4swY0aNCgQYMGDRo0aNCgQYMGDRo0aNCgQYMGDfrnugkwALhbOXXwEMtoAAAAAElFTkSuQmCC')
                    #{$rdirection} no-repeat;
                  background-size: 23px 43px;
                  transform: rotate(0);
                  margin: 0;
                }
              }
            }
            .pr-rd-sort-group {
              .pr-rd-sort {
                @if $typography_cr24 == true {
                  @include body-r;
                }
              }
            }
          }
        }
        .pr-rd-review-total {
          @if $typography_cr24 == true {
            @include body-l-bold;
          }
        }
        .pr-rd-display-search-no-results h2 {
          font-family: $primary-font;
        }
      }
      @include breakpoint($small-down) {
        .pr-flag-review {
          padding: 10px 20px;
        }
        .pr-flag-review-fieldset {
          margin-bottom: 10px;
        }
        .pr-rd-footer {
          .pr-modal {
            top: 46%;
          }
        }
      }
    }
    .pr-review-snapshot-snippets {
      @include pr-large-background-image();
      .pr-snippet-stars-reco-inline {
        .pr-snippet-read-and-write span {
          font-size: 14px !important;
        }
        .pr-star-v4 {
          height: 28px;
          width: 28px;
        }
        .pr-snippet-read-and-write {
          text-transform: uppercase;
          border: 1px solid $color-off-black;
          padding: 10px 30px;
          background: $color-off-black;
          display: inline-block;
          margin-top: 45px;
          margin-#{$ldirection}: 0;
          @include breakpoint($landscape-up) {
            display: block;
            margin-top: 55px;
          }
          .pr-snippet-review-count {
            position: absolute;
            top: -35px;
            #{$ldirection}: 5px;
            font-size: 20px !important;
            font-style: normal;
            @include breakpoint($landscape-up) {
              display: none;
            }
          }
          a {
            @if $typography_cr24 == true {
              @include link-1-uppercase;
              font-size: 0.875rem !important;
              @include breakpoint($landscape-up) {
                font-size: 0.875rem !important;
              }
            } @else {
              font-family: $primary-font;
            }
            color: $white;
          }
        }
        .pr-snippet-stars-container {
          vertical-align: bottom;
          @include breakpoint($small-down) {
            float: $ldirection;
          }
        }
      }
    }
    .pr-rating-stars {
      padding-bottom: 0;
    }
  }
  .pr-rd-footer {
    background-color: $white;
    .pr-rd-bottomline span {
      @if $typography_cr24 == false {
        font-size: 14px;
      }
    }
    &.pr-rd-content-block {
      margin: 20px 0 0;
      @include breakpoint($landscape-up) {
        margin: 20px 0 15px;
      }
    }
  }
  .pr-flag-review-btn {
    &.pr-flag-review-btn-submit {
      &:hover {
        color: $white;
      }
      background: $color-off-black;
      font-family: $primary-font;
    }
    &.pr-flag-review-btn-cancel {
      background: $color-off-black;
      font-family: $primary-font;
      color: $white;
    }
  }
}

.product-grid-wrapper {
  .p-w-r {
    .pr-snippet {
      .pr-snippet-stars-png {
        .pr-star-v4-0-filled,
        .pr-star-v4-25-filled,
        .pr-star-v4-50-filled,
        .pr-star-v4-75-filled,
        .pr-star-v4-100-filled {
          width: 15px;
          height: 15px;
        }
      }
    }
    .pr-category-snippet__item {
      @include breakpoint($small-down) {
        display: block;
      }
    }
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-compact {
        .pr-snippet-read-and-write {
          margin-top: 5px;
        }
      }
      .pr-snippet-read-and-write {
        .pr-snippet-review-count,
        .pr-snippet-write-review-link {
          @if $typography_cr24 == true {
            @include text-disclaimer;
            font-size: 0.75rem !important;
            @include breakpoint($landscape-up) {
              font-size: 0.75rem !important;
            }
          }
        }
      }
    }
  }
  .pr-category-snippet__total {
    padding-top: 4px;
    @include breakpoint($small-down) {
      text-align: center;
    }
  }
  .product-rating__stars-wrapper {
    @include breakpoint($small-down) {
      width: 100%;
    }
  }
  .product-brief__price {
    @include breakpoint($small-down) {
      clear: $ldirection;
    }
  }
  .review-snippet {
    display: block;
    .pr-snippet {
      width: 220px;
      display: flex;
      align-items: center;
      @include breakpoint($small-down) {
        width: 100%;
      }
      .pr-category-snippet__total.pr-category-snippet__item,
      .pr-snippet-write-review-link {
        display: none;
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    .pr-star-v4-0-filled,
    .pr-star-v4-25-filled,
    .pr-star-v4-50-filled,
    .pr-star-v4-75-filled,
    .pr-star-v4-100-filled {
      width: 20px;
      height: 20px;
    }
    .pr-star-v4-0-filled {
      background-image: url(/media/images/stars/v2/rating-0.png);
    }
    .pr-star-v4-100-filled {
      background-image: url(/media/images/stars/v2/rating-100.png);
    }
  }
}

.spp_product__review-snippet {
  .p-w-r {
    .pr-snippet {
      .pr-snippet-stars-png {
        @include pr-background-image();
      }
    }
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-read-and-write {
          @include breakpoint($small-down) {
            margin-#{$ldirection}: 0;
            width: 100%;
            font-size: 12px;
            .pr-snippet-review-count {
              display: block;
              border-#{$rdirection}: none;
            }
          }
        }
      }
    }
    .pr-review-snippet-container {
      .pr-snippet-stars-reco-inline.pr-snippet-minimal {
        .pr-snippet-stars-reco-reco {
          @include breakpoint($small-down) {
            display: none;
          }
        }
      }
    }
  }
}

#pr-flag-reviews {
  .pr-flag-review-label {
    font-family: $primary-font;
    b {
      font-family: $primary-font;
      color: $color-off-black;
    }
  }
  input[type='radio'] {
    #{$ldirection}: auto;
  }
}

.product-full__details {
  .p-w-r {
    .pr-snippet {
      .pr-snippet-read-and-write {
        .pr-snippet-review-count,
        .pr-snippet-write-review-link {
          font-family: $primary-font;
          @if $typography_cr24 == false {
            font-size: 14px !important;
          }
        }
        .pr-snippet-write-review-link {
          @if $typography_cr24 == false {
            display: inline-block;
          }
          @include breakpoint($small-down) {
            padding-#{$ldirection}: 0;
            margin-top: 4px;
            @if $pdp_modernized_design == true {
              display: none;
            } @else {
              display: table;
            }
          }
          @media (min-width: $portrait-up) {
            @include swap_direction(padding, 4px 9px 4px 0);
            margin-#{$rdirection}: 3px;
          }
          @include breakpoint($iphoneX-portrait) {
            padding-#{$rdirection}: 8px;
          }
          @if $pdp_modernized_design == true {
            display: none;
          } @else {
            display: table;
          }
        }
        display: inline;
      }
      .pr-snippet-stars-png {
        .pr-snippet-rating-decimal {
          @if $typography_cr24 == true {
            @include body-r;
          } @else {
            font-size: 14px;
            font-family: $primary-font;
          }
          display: inline;
          background: $white;
          border: none;
          vertical-align: sub;
          padding: 1px;
          color: $color-off-black;
        }
        .pr-star-v4 {
          width: 15px;
          height: 15px;
        }
      }
      width: 100%;
      @include breakpoint($small-down) {
        width: 103%;
      }
      @if $typography_cr24 == true {
        display: flex;
      }
    }
    .pr-snippet-stars-reco-reco {
      display: none;
    }
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-read-and-write {
          text-transform: capitalize;
          @include breakpoint($small-down) {
            margin-#{$ldirection}: 7px;
          }
          .pr-snippet-review-count {
            border-#{$rdirection}: none;
          }
        }
        .pr-snippet-stars-container {
          padding-#{$rdirection}: 10px;
          cursor: pointer;
          @if $search_modernized_design == true {
            .product-results & {
              padding-#{$rdirection}: 0;
            }
          }
        }
      }
      .pr-snippet-read-and-write {
        margin-#{$ldirection}: 0;
        .pr-snippet-review-count {
          @include breakpoint($small-down) {
            margin-#{$rdirection}: 0;
          }
          @if $typography_cr24 == true {
            @include body-r;
            /* stylelint-disable-next-line declaration-no-important */
            font-size: 1rem !important;
            @include breakpoint($landscape-up) {
              /* stylelint-disable-next-line declaration-no-important */
              font-size: 1rem !important;
            }
            &:hover {
              @if $typography_cr24 == true {
                text-decoration: underline;
              }
            }
          }
          padding-#{$rdirection}: 0;
          margin-#{$rdirection}: 4px;
        }
      }
      &.pr-snippet-compact {
        .pr-snippet-read-and-write {
          @if $typography_cr24 == true {
            margin-top: 3px;
          }
        }
      }
    }
  }
}

.esearch-nav .p-w-r .pr-snippet-stars-reco-reco {
  display: none;
}

.esearch-nav {
  .product-grid-wrapper {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-review-count {
          @if $typography_cr24 == true {
            @include body-r;
            /* stylelint-disable-next-line declaration-no-important */
            font-size: 1rem !important;
            @include breakpoint($landscape-up) {
              /* stylelint-disable-next-line declaration-no-important */
              font-size: 1rem !important;
            }
          }
        }
      }
    }
  }
}

.spp__container {
  .spp_customer_reviews {
    .spp_reviews_button {
      display: inline-block;
      margin-top: 11px;
    }
    #pr-reviewdisplay {
      .p-w-r {
        @include breakpoint($small-down) {
          margin-top: 3 5px;
        }
      }
    }
  }
}
/* Ask & Answer */
@media (min-width: $portrait-up) {
  .product-full {
    &__rating {
      .p-w-r {
        .pr-review-snippet-container {
          float: $ldirection;
        }
      }
    }
    &__description,
    &__description--enhance {
      clear: both;
    }
  }
}

#pr-questionsnippet {
  .p-w-r {
    .pr-qa-snippet-container {
      .pr-snippet-qa {
        .pr-snippet-qa-answer-link {
          position: absolute;
          width: 140px;
          top: 27px;
          #{$ldirection}: 100px;
          &.pr-ask-question-link-first {
            #{$ldirection}: 151px;
          }
          border-#{$ldirection}: 1px solid $color-off-black;
          padding-#{$ldirection}: 10px;
          @if $pdp_modernized_design == true {
            display: none;
          }
        }
        @media (min-width: $portrait-up) {
          display: inline;
        }
        .pr-qa-large,
        .pr-answered-question-circle {
          display: none;
        }
      }
      .pr-ask-question-link {
        @media (min-width: $landscape-up) {
          margin: 0;
          top: 27px;
        }
        position: absolute;
        top: 22px;
        #{$ldirection}: 111px;
        &.pr-ask-question-link-first {
          #{$ldirection}: 140px;
        }
        border-#{$ldirection}: 1px solid $color-off-black;
        padding-#{$ldirection}: 10px;
        @if $pdp_modernized_design == true {
          display: none;
        }
      }
    }
  }
}

.product-full__rating {
  .p-w-r {
    .pr-ask-question-cta {
      &.pr-qa-large {
        display: none;
      }
    }
    .pr-ask-question-subtext {
      display: none;
    }
  }
}

.product-full__top {
  .product-full__breadcrumb {
    clear: both;
  }
}

#pr-question-form-link {
  @media (min-width: $portrait-up) {
    display: inherit;
    width: 35%;
    margin: 15px 0 10px 0;
    clear: both;
  }
  @if $typography_cr24 == true {
    @include body-xs-bold;
    font-size: 0.875rem !important;
    @include breakpoint($landscape-up) {
      font-size: 0.875rem !important;
    }
  }
  background-color: $white;
  outline: none;
  border: 1px solid $color-off-black;
  color: $color-off-black;
  top: inherit;
  z-index: 1;
  display: flex;
  height: 45px;
  float: $rdirection;
  margin-#{$rdirection}: 15px;
  &[aria-expanded='true'] {
    width: 20%;
  }
  &:hover {
    background-color: $color-off-black;
    color: $white;
  }
  .pr-qa-display-link {
    width: 100%;
    color: $color-off-black;
    padding: 0 20px;
    &:hover {
      color: $white;
      background-image: none;
      text-shadow: none;
    }
  }
}

.tab-2 {
  .pr-qa-display {
    .pr-qa-display-ask-question {
      .pr-question-form fieldset:first-child {
        @media (min-width: $portrait-up) {
          position: relative;
        }
      }
      .pr-btn-ask-question {
        .pr-cross-icon {
          display: none;
        }
      }
    }
  }
  .pr-question-form {
    .pr-waq-legal-text {
      span {
        color: $color-off-black;
        line-height: 17px;
      }
      a {
        color: $color-off-black;
      }
    }
  }
}

.pr-question-form {
  .pr-header-required {
    display: none;
  }
  .form-group {
    .form-control {
      border: 1px solid $color-off-black;
    }
    .pr-helper-text {
      color: $color-off-black;
    }
    .btn-group {
      .pr-btn-default {
        border-color: $color-off-black;
        &:hover {
          background-color: $white;
          color: $color-off-black;
          outline: none;
        }
      }
    }
  }
}

.tabs-content {
  .p-w-r {
    margin-#{$ldirection}: 0;
    margin-#{$rdirection}: 0;
    .pr-qa-display {
      background: $white;
      padding: 0;
      font-family: $primary-font;
      &.pr-qa-display-desktop {
        @media (min-width: $portrait-up) {
          padding-#{$ldirection}: 0;
          padding-#{$rdirection}: 0;
        }
        .pr-qa-display-search {
          form {
            label {
              display: none;
            }
            .pr-qa-display-searchbar {
              button {
                outline: none;
                &:focus {
                  border: none;
                }
              }
              input {
                @if $typography_cr24 == true {
                  @include body-r-bold;
                  text-transform: uppercase;
                  @include breakpoint($landscape-up) {
                    text-transform: uppercase;
                  }
                } @else {
                  font-family: $primary-font;
                }
                &:focus + button {
                  &.pr-qa-search-filled {
                    background-color: $color-off-black;
                  }
                }
              }
              .pr-qa-display-clear-search-button {
                color: $color-off-black;
              }
            }
          }
        }
        .pr-qa-display-ask-question {
          @media (min-width: $portrait-up) {
            padding-#{$ldirection}: 0;
            width: 100%;
            float: $ldirection;
            margin-bottom: 0;
          }
          float: none;
          .pr-question-form {
            @media (min-width: $portrait-up) {
              input[type='text'] {
                width: 58% !important;
              }
            }
          }
        }
        .pr-qa-sort-group {
          label {
            display: none;
          }
        }
        .pr-qa-display-answer-by-container {
          &.pr-qa-display-answer-by-container-expert {
            @media (min-width: $portrait-up) {
              padding-#{$ldirection}: 0;
              border-#{$ldirection}: none;
            }
            .pr-qa-display-answer-details {
              padding-#{$ldirection}: 20px;
            }
          }
        }
        .pr-qa-display-answer {
          .pr-qa-display-answer-icon {
            display: none;
          }
        }
        .pr-qa-display-headline {
          @media (min-width: $portrait-up) {
            margin-#{$ldirection}: 0;
          }
        }
        .pr-qa-display-question {
          width: 100%;
        }
        .pr-qa-display-questionlist {
          @include swap_direction(padding, 10px 0 10px 25%);
          margin-bottom: 20px;
        }
        .pr-qa-display-item-details {
          #{$ldirection}: 0;
          margin-top: 0;
          top: 0;
        }
        .pr-qa-display-add-answer {
          margin-#{$ldirection}: 0;
        }
      }
      .pr-qa-display-search {
        @media (min-width: $portrait-up) {
          width: 35%;
          float: $rdirection;
          clear: both;
          margin-top: 5px;
          padding: 0;
          .pr-qa-display-clear-search-button {
            color: $color-off-black;
          }
        }
        padding: 10px;
        background-color: $white;
        form {
          .pr-qa-display-searchbar {
            input {
              border: 1px solid $color-off-black;
              background-color: $white;
              font-family: $primary-font;
              padding-#{$ldirection}: 10px;
              padding-top: 10px;
              font-weight: 700;
              &:focus,
              &:active {
                border: 1px solid $color-off-black;
                &:active + button {
                  border: 1px solid $color-off-black;
                  &.pr-qa-search-filled {
                    background-color: $color-off-black;
                  }
                }
              }
              &:active + button,
              &:focus + button {
                &.pr-qa-search-filled {
                  background-color: $color-off-black;
                  border: 1px solid $color-off-black;
                }
                &.pr-qa-search-empty {
                  span {
                    svg {
                      path {
                        fill: $color-off-black;
                      }
                    }
                  }
                }
              }
            }
            .pr-qa-search-empty {
              background-color: transparent;
              border: none;
              width: auto !important;
              text-align: $rdirection;
              span {
                svg {
                  path {
                    fill: $color-off-black;
                  }
                }
              }
            }
            button {
              background-color: $color-off-black;
              &:active,
              &:focus {
                border: 1px solid $color-off-black;
              }
            }
          }
          label {
            display: none;
          }
        }
      }
      &.pr-qa-display-tablet {
        .pr-qa-display-ask-question {
          @media (min-width: $portrait-up) {
            margin-bottom: 0;
            width: 100%;
          }
        }
      }
      .pr-qa-sort {
        @if $typography_cr24 == true {
          @include body-xs;
        }
        width: 100%;
        border: 1px solid $color-off-black;
        padding-top: 7px;
        @media (min-width: $portrait-up) {
          width: 30%;
          float: $rdirection;
          background: $white;
        }
      }
      .pr-subscript {
        @if $typography_cr24 == true {
          @include body-r;
        } @else {
          font-size: 16px;
        }
        color: $color-off-black;
      }
      .pr-qa-display-item-details {
        margin-top: 15px;
        @include breakpoint($small-down) {
          padding-#{$ldirection}: 42px;
        }
        time {
          @if $typography_cr24 == true {
            @include body-r;
          } @else {
            font-size: 16px;
          }
          margin-#{$ldirection}: 0;
          display: block;
          color: $color-off-black;
        }
      }
      .pr-qa-display-answer {
        .pr-qa-display-answer-icon {
          display: none;
        }
        .pr-qa-display-answer-details {
          border-#{$ldirection}: 1px solid $color-off-black;
          padding-top: 15px;
          padding-#{$ldirection}: 20px;
          @include breakpoint($small-down) {
            margin-#{$ldirection}: 42px;
          }
        }
        .pr-helpful-voting {
          .pr-helpful-active {
            border: 1px solid $color-off-black;
            background: $color-off-black !important;
            span {
              svg {
                path {
                  fill: $white;
                }
              }
            }
            .pr-helpful-count {
              color: $white;
            }
            &:hover {
              color: $white;
            }
          }
          .pr-thumbs-icon {
            svg {
              path {
                fill: $color-off-black;
              }
              &:hover {
                fill: $white;
              }
            }
          }
          .pr-helpful-btn {
            background-color: $white;
            min-width: auto;
            border: none;
            &:hover {
              background: $color-off-black;
              outline: none;
              .pr-thumbs-icon {
                svg {
                  path {
                    fill: $white;
                  }
                }
              }
              .pr-helpful-count {
                color: $white;
              }
            }
            &.pr-helpful-yes {
              border-#{$rdirection}: 1px solid $color-off-black;
            }
          }
        }
      }
      .pr-btn-answer {
        .pr-cross-icon {
          display: none;
        }
      }
      span {
        @if $typography_cr24 == true {
          @include body-r;
        } @else {
          font-size: 16px;
        }
        color: $color-off-black;
      }
      .pr-qa-display-item-text {
        @if $typography_cr24 == true {
          @include body-r;
        }
      }
      .pr-qa-sort-group {
        @media (min-width: $portrait-up) {
          margin-top: 8px;
        }
        &:after {
          border-width: 5px 5px 0;
          #{$rdirection}: 20px;
          border-color: $color-off-black transparent;
          @media (min-width: $portrait-up) {
            top: 12px;
          }
        }
        width: 100%;
        margin-top: 10px;
        .pr-qa-sort {
          &::-ms-expand {
            display: none;
          }
        }
      }
      .pr-qa-display-sort {
        @media (min-width: $portrait-up) {
          flex-direction: inherit;
          padding: 0 0 15px 0;
          clear: both;
          border-bottom: 1px solid $color-off-black;
        }
        flex-direction: column-reverse;
        display: flex;
        font-family: $primary-font;
        padding: 12px;
        border-bottom: 1px solid $color-off-black;
      }
      .pr-qa-display-questionlist {
        border-#{$ldirection}: 0;
        border-bottom: 1px solid $color-off-black;
        @include breakpoint($small-down) {
          padding-#{$ldirection}: 0;
          margin-bottom: 30px;
        }
        .pr-qa-display-add-answer {
          .pr-waqwaa {
            @media (min-width: $portrait-up) {
              margin-#{$ldirection}: 0;
            }
            @include breakpoint($small-down) {
              margin-#{$ldirection}: 40px;
            }
          }
        }
      }
      .pr-qa-sort-headline {
        @if $typography_cr24 == true {
          @include body-l-bold;
        } @else {
          font-weight: 500;
          font-size: 19px;
        }
        @media (min-width: $portrait-up) {
          width: 54%;
          margin: 0;
          padding-top: 10px;
        }
        padding-top: 19px;
      }
      .pr-qa-display-answer-link-container {
        @media (min-width: $portrait-up) {
          margin-#{$ldirection}: 0;
          padding: 0;
          width: 45%;
        }
        margin-#{$ldirection}: 30px;
        padding: 0 12px;
        .pr-btn-answer {
          border: 1px solid $color-off-black;
          padding: 12px;
          color: $color-off-black;
          &:hover {
            color: $white;
            background: $color-off-black;
          }
          .pr-qa-display-text {
            color: inherit;
            text-shadow: none;
            background-image: none;
          }
        }
        .pr-qa-display-text {
          color: $color-off-black;
          &:hover {
            color: inherit;
          }
        }
      }
      .pr-qa-display-question {
        @include breakpoint($small-down) {
          margin-#{$ldirection}: 0;
        }
      }
      .pr-qa-display-item {
        @include breakpoint($small-down) {
          padding-#{$ldirection}: 42px;
        }
        .pr-qa-display-item-icon {
          @if $typography_cr24 == true {
            @include body-r;
          }
          @include breakpoint($small-down) {
            #{$ldirection}: 10px;
          }
          background-color: $color-off-black;
        }
      }
      .pr-qa-display-headline {
        margin: 0 0 20px 0;
      }
      .pr-waqwaa {
        margin-#{$ldirection}: 15px;
        margin-#{$rdirection}: 15px;
        @media (min-width: $landscape-up) {
          margin: 0 auto;
        }
        .form-control {
          border: 1px solid $color-off-black;
        }
      }
      .pr-qa-display-btn-text {
        color: $white;
        span {
          color: $white;
        }
      }
      .pr-caret-icon__line {
        stroke: $white;
      }
      .pr-qa-display-show-more-questions {
        background-color: $color-off-black;
        border: 1px solid $color-off-black;
        &:hover {
          background-color: $white;
          .pr-qa-display-btn-text {
            color: $color-off-black;
            span {
              color: $color-off-black;
            }
          }
          .pr-caret-icon__line {
            stroke: $color-off-black;
          }
        }
      }
      .pr-btn-show-more {
        &.pr-qa-display-link {
          display: none;
        }
      }
      .pr-qa-display-thankyou-headline {
        @include breakpoint($small-down) {
          padding-bottom: 20px;
        }
      }
    }
    .btn-group-radio-vertical {
      .pr-btn.active {
        background-color: $color-off-black;
        color: $white;
        outline: none;
      }
    }
  }
}

.p-w-r {
  .pr-qa-display-headline {
    h1 {
      display: none;
    }
  }
  .pr-answer-form {
    .pr-submit {
      .pr-accessible-btn {
        background-color: $white;
        border: 1px solid $color-off-black;
        color: $color-off-black;
        &:hover {
          background-color: $color-off-black;
          color: $white;
        }
      }
    }
  }
}

.pr-qa-display-ask-question {
  .pr-submit {
    @media (min-width: $portrait-up) {
      margin-#{$ldirection}: 40%;
    }
    .pr-accessible-btn {
      @media (min-width: $portrait-up) {
        width: 60%;
        float: $rdirection !important;
      }
      background: $color-off-black;
      color: $white;
      border: 1px solid $color-off-black;
      &:hover {
        background-color: $white;
        color: $color-off-black;
        border: 1px solid $color-off-black;
      }
    }
  }
  .p-w-r {
    .pr-waqwaa {
      @media (min-width: $portrait-up) {
        max-width: 100%;
        width: 600px;
        margin: 0 auto;
      }
      max-width: 100%;
    }
  }
}

.pr-qa-hide-answer-link .pr-qa-display-answer-link-container {
  display: none;
}

$pr_bestuses: false !default;

@if $pr_bestuses == true {
  #pr-war-form {
    .pr-pros-form-group {
      @if $typography_cr24 == true {
        @include body-r-bold;
      }
      display: block;
    }
    .pr-describeyourself-form-group {
      display: none;
    }
  }
  #pr-write {
    .pr-pros-form-group {
      &:before {
        @if $typography_cr24 == true {
          @include body-r-bold;
        } @else {
          font-family: $primary-font;
          font-size: 12px;
          @include breakpoint($landscape-up) {
            font-size: 16px;
            font-weight: bold;
          }
        }
        content: attr(data-before-content);
        color: $color-off-black;
      }
      #pr-pros {
        margin-top: 5px;
        .pr-control-label {
          display: none;
        }
      }
    }
  }
}
